import React from "react";
import './styles.css';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import 'moment/locale/pt-br';
import { CImage } from "@coreui/react";
import ArrowUp from '../../assets/arrow-up.svg';
import ArrowDown from '../../assets/arrow-down.svg';
import { Dots } from 'react-activity';
import 'react-activity/dist/Dots.css';

const Table = ({ tableData = [], orderBy, setOrderBy, orderType, setOrderType, handleOrderByChange, localLoading }) => {

    moment.locale('pt-br');
    const navigate = useNavigate();

    const navigateOnClick = (rowData) => {
        navigate('/history', {
            state: {
                rowData
            }
        })
    }

    const handleTableHeaderClick = (header) => {
        // when order by changes, set it and send type as ascending.
        if (orderBy !== header) {
            setOrderBy(header);
            setOrderType('ASC');
            handleOrderByChange(header, 'ASC');
            return;
        }
        // when order by is the same and type is asc, change type to descending.
        if (orderType === 'ASC') {
            setOrderType('DESC');
            handleOrderByChange(header, 'DESC');
            return;
        }
        // Last possibility is when order by is the same as previous one and type is decending.
        // Now, remove filter by setting order by to undefined
        setOrderBy(undefined);
        handleOrderByChange(undefined, 'ASC');
    }

    return (
        <div
            className='AdminTable'
        >
            <table>
                <thead>
                    <tr>
                        <th>Foto</th>
                        <th
                            onClick={() => handleTableHeaderClick('id')}
                            className={`hoverBlue cursor-pointer ${orderBy === 'id' ? 'orderActive' : ''}`}
                        >
                            ID
                            { orderBy === 'id' && orderType === 'ASC' &&
                                <img
                                    src={ArrowUp}
                                    alt='up'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                /> 
                            }
                            { orderBy === 'id' && orderType === 'DESC' &&
                                <img
                                    src={ArrowDown}
                                    alt='down'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                /> 
                            }
                        </th>
                        <th
                            onClick={() => handleTableHeaderClick('totem')}
                            className={`hoverBlue cursor-pointer ${orderBy === 'totem' ? 'orderActive' : ''}`}
                        >
                            Totem
                            { orderBy === 'totem' && orderType === 'ASC' &&
                                <img
                                    src={ArrowUp}
                                    alt='up'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                /> 
                            }
                            { orderBy === 'totem' && orderType === 'DESC' &&
                                <img
                                    src={ArrowDown}
                                    alt='down'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                /> 
                            }
                        </th>
                        <th
                            onClick={() => handleTableHeaderClick('unit')}
                            className={`hoverBlue cursor-pointer ${orderBy === 'unit' ? 'orderActive' : ''}`}
                        >
                            Unidade
                            { orderBy === 'unit' && orderType === 'ASC' &&
                                <img
                                    src={ArrowUp}
                                    alt='up'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                /> 
                            }
                            { orderBy === 'unit' && orderType === 'DESC' &&
                                <img
                                    src={ArrowDown}
                                    alt='down'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                /> 
                            }
                        </th>
                        <th
                            onClick={() => handleTableHeaderClick('date')}
                            className={`hoverBlue cursor-pointer ${orderBy === 'date' ? 'orderActive' : ''}`}
                        >
                            Data de cadastro
                            { orderBy === 'date' && orderType === 'ASC' &&
                                <img
                                    src={ArrowUp}
                                    alt='up'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                /> 
                            }
                            { orderBy === 'date' && orderType === 'DESC' &&
                                <img
                                    src={ArrowDown}
                                    alt='down'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                /> 
                            }
                        </th>
                        <th
                            onClick={() => handleTableHeaderClick('time')}
                            className={`hoverBlue cursor-pointer ${orderBy === 'time' ? 'orderActive' : ''}`}
                        >
                            Hora de cadastro
                            { orderBy === 'time' && orderType === 'ASC' &&
                                <img
                                    src={ArrowUp}
                                    alt='up'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                /> 
                            }
                            { orderBy === 'time' && orderType === 'DESC' &&
                                <img
                                    src={ArrowDown}
                                    alt='down'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                /> 
                            }
                        </th>
                    </tr>
                </thead>
                {!localLoading && 
                <tbody>
                    {tableData.map((data, index) => (
                        <tr key={index}>
                            <td>
                            {data.picture === null
                                    ? <div className='ImagePlaceHolder'>JPG</div>
                                    : <CImage className='Image' align="center" src={data.picture} />
                                }
                            </td>
                            <td
                                className={'cursor-pointer'}
                                onClick={() => navigateOnClick(data)}
                            >
                                {data.id_table}
                            </td>
                            <td className='text-medium'>
                                {data.totem_name}
                            </td>
                            <td>
                                {data.unit}
                            </td>
                            <td>
                                {moment(data.datetime).format('DD MMMM YYYY')}
                            </td>
                            <td>
                                {moment(data.datetime).format('HH:mm [hrs]')}
                            </td>
                        </tr>
                    ))}
                </tbody>
                }
            </table>
            {localLoading &&
                <div className="Loading">
                    <Dots color='#000' />
                </div>
            }
        </div>
    );
}

export default Table;
