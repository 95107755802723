import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    useLocation
} from "react-router-dom";
import { useSelector } from 'react-redux';

import Menu from '../components/Menu';
import TopBar from '../components/TopBar';

import HomePage from '../pages/HomePage';
import AccessHistory from '../pages/AccessHistory';
import Dashboard from '../pages/Dashboard';
// import MenuClerk from "../components/MenuClerk";
import HomeClerk from "../pages/HomeClerk";
import EditUser from '../pages/EditUser';


const PrivateRoute = ({ children }) => {
    const isAuthorized = useSelector(state => state.user.isAuthorized)
    const location = useLocation();

    if (!isAuthorized) {
        return <Navigate to="/not-authorized" state={{ from: location }} />;
    }

    return children;
}

const Router = () => {

    const userType = 'admin'; // atendente
    
    if (userType === 'admin') {
        return (
            <div>
                <TopBar />
                <div>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<PrivateRoute><Menu /></PrivateRoute>}>
                                <Route index element={<HomePage />} />
                                <Route path='/dashboard' element={<Dashboard />} />
                                <Route path='/history' element={<AccessHistory />} />
                            </Route>
                            <Route path='*' element={<Navigate to='/' />} />
                        </Routes>
                    </BrowserRouter>
                </div>
            </div>
        );
    }

    return (
        <div>
            <TopBar />
            <div>
                <BrowserRouter>
                    <Routes>
                        {/* <Route path="/" element={<PrivateRoute></PrivateRoute>}> */}
                            <Route index element={<HomeClerk />} />
                            <Route path='/editUser' element={<EditUser />} />
                        {/* </Route> */}
                        <Route path='*' element={<Navigate to='/' />} />
                    </Routes>
                </BrowserRouter>
            </div>
        </div>
    );
}

export default Router;
