import React from "react";
import './styles.css';
import Einstein from '../../assets/albert-einstein-logo.png';
import keycloakService from '../../services/auth.keycloak';

const TopBar = () => {
    const userType = 'admin'
    return (
        <div className='TopBar' style={ userType === 'admin' ? { padding: '0 70px 0 31.5px' } : { padding: '0 70px 0 20px' } }>
            <img
                alt='logo'
                src={Einstein}
            />
            <div className='UserInfo'>
                <img
                    alt='profilePicture'
                    src='https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg'
                />
                <span>{keycloakService.resolveGivenName()}</span>
            </div>
        </div>
    )
}

export default TopBar;
