import { CCol, CCardBody, CCardText, CCard, CRow, CProgress, CProgressBar } from "@coreui/react";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"
import './style.css'

const CardList = ({ nameUnd, colorProgress, porcent, value }) => {
    return ( 
        <CCard className="card-unidade">
            <CCardBody>
                <CRow>
                    <CCol>
                        <CCardText className="title1">{nameUnd}</CCardText>
                    </CCol>
                    <CCol>
                        <CCardText className="title2">{value}</CCardText>
                    </CCol>
                </CRow>
                <CProgress className="mb-1" height={7}>
                    <CProgressBar color={colorProgress} value={porcent}/>
                </CProgress>
            </CCardBody>
        </CCard>     
            
    )
}

export default CardList