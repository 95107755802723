import jsonAsXlsx from 'json-as-xlsx';

const generate = (columns, content, sheetName, fileName) => {

    const data = [{
        sheet: sheetName,
        columns: columns,
        content: content
    }];

    const settings = {
        fileName: fileName
    }

    jsonAsXlsx(data, settings);
}

const xlsx = {
    generate
}

export default xlsx;
