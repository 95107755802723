import React, { useState } from "react";
import Proptypes from 'prop-types';
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { pt } from 'date-fns/locale'

const DateRangeFilter = ({ onChange }) => {

	const [state, setState] = useState([
		{
			startDate: new Date(),
			endDate: addDays(new Date(), 0),
			key: "selection"
		}
	]);

	const handleOnChange = (ranges) => {
		const { selection } = ranges;
		onChange(selection);
		setState([selection]);
	};

	return (
		<DateRange
			locale={pt}
			onChange={handleOnChange}
			ranges={state}
			linkedCalendars={true}
			disableDaysBeforeToday={true}
			showDateDisplay={false}
			maxDate={new Date()}
		/>
	);
};

DateRangeFilter.propTypes = {
	onchange: Proptypes.func
};

export default DateRangeFilter;