import { getRequest, deleteRequest } from "./api";

// HOME
export const getPerson = (page, limit, id = '', totem = '', unit = '', date = '', orderBy, orderType = 'ASC') => {
    let query = `?limit=${limit}&page=${page}&id=${id}&totem=${totem}&unit=${unit}&date=${date}`
    if (orderBy) query+= `&orderBy=${orderBy}&orderType=${orderType}`
    return getRequest(`/api/person${query}`);
}

export const getPersonLogById = (id_person) => {
    return getRequest(`/api/person/${id_person}/log`);
}

export const deletePerson = (id_person) => {
    return deleteRequest(`/api/person/${id_person}`);
}

// DASHBOARD
export const getNumberTotal = (description, startDate, endDate) => {
    return getRequest(`/api/log?description=${description}&startDate=${startDate}&endDate=${endDate}`);
}

export const getUnitNumberTotal = (description, startDate, endDate) => {
    return getRequest(`/api/log/unit?description=${description}&startDate=${startDate}&endDate=${endDate}`);
}