import { CCard, CCardBody, CCardText, CCol, CRow, CImage, CButton } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import Card from '../../components/Card';
import CardList from '../../components/CardList';
import './style.css';
import imageBoneco from '../../assets/bonecos.png';
import imageFace from '../../assets/face_1.png';
import imageExclusao from '../../assets/delete.png';
import imageFoto from '../../assets/camera.png';
// import { useAuth } from 'oidc-react';
import { getNumberTotal, getUnitNumberTotal } from '../../services/httpRequest';
import DateRangeFilter from '../../components/DateRangeFilter';
import moment from 'moment';
import calendar from '../../assets/calendar-svgrepo-com.svg';
import { Dots } from 'react-activity';
import 'react-activity/dist/Dots.css';
import jsonAsXlsx from 'json-as-xlsx';

const Dashboard = () => {

  // const auth = useAuth();
  const [viewData, setViewData] = useState(0);
  const [cad, setCad] = useState([]);
  const [face, setFace] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [updatePicture, setUpdatePicture] = useState([]);
  const [cadList, setCadList] = useState([]);
  const [faceList, setFaceList] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [updatePictureList, setUpdatePictureList] = useState([]);
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
    }
  ]);

  useEffect(() => {
      setGlobalLoading(true)

      const dateNow = [
        {
          startDate: new Date(),
          endDate: new Date(),
        }];

      const startDate = moment(dateNow.startDate).format('yyyy-MM-DD [00:00:00]');
      const endDate = moment(dateNow.endDate).format('yyyy-MM-DD [23:59:59]');

      // created
      getNumberTotal('create', startDate, endDate).then((response) => {
        setCad(response);
      })
        .catch((err) => {
          setGlobalLoading(false);
          console.log({ err });
        });

      getUnitNumberTotal('create', startDate, endDate).then((response) => {
        setCadList(response);
      })
        .catch((err) => {
          setGlobalLoading(false);
          console.log({ err });
        })

      // facematch
      getNumberTotal('facematch', startDate, endDate).then((response) => {
        setFace(response);
      })
        .catch((err) => {
          setGlobalLoading(false);
          console.log({ err });
        });

      getUnitNumberTotal('facematch', startDate, endDate).then((response) => {
        setFaceList(response);
      })
        .catch((err) => {
          setGlobalLoading(false);
          console.log({ err });
        })

      // deleted
      getNumberTotal('delete', startDate, endDate).then((response) => {
        setDeleted(response);
      })
        .catch((err) => {
          setGlobalLoading(false);
          console.log({ err });
        });

      getUnitNumberTotal('delete', startDate, endDate).then((response) => {
        setDeleteList(response);
      })
        .catch((err) => {
          setGlobalLoading(false);
          console.log({ err });
        })

      // updated
      getNumberTotal('update', startDate, endDate).then((response) => {
        setUpdatePicture(response);
      })
        .catch((err) => {
          setGlobalLoading(false);
          console.log({ err });
        });

      getUnitNumberTotal('update', startDate, endDate).then((response) => {
        setUpdatePictureList(response);
      })
        .catch((err) => {
          setGlobalLoading(false);
          console.log({ err });
        })
      setGlobalLoading(false)
    // }
  }, [
    // auth.userData
  ]);


  const handleClick = () => {
    setIsShowCalendar(false);
    setLocalLoading(true);
    if (setDate) {
      const startDate = moment(date.startDate).format('yyyy-MM-DD [00:00:00]');
      const endDate = moment(date.endDate).format('yyyy-MM-DD [23:59:59]');

      // created
      getNumberTotal('create', startDate, endDate).then((response) => {
        setCad([]);
        setCad(response);
      })
        .catch((err) => {
          setLocalLoading(false);
          console.log({ err });
        });

      getUnitNumberTotal('create', startDate, endDate).then((response) => {
        setCadList([]);
        setCadList(response);
      })
        .catch((err) => {
          setLocalLoading(false);
          console.log({ err });
        })

      // facematch
      getNumberTotal('facematch', startDate, endDate).then((response) => {
        setFace([]);
        setFace(response);
      })
        .catch((err) => {
          setLocalLoading(false);
          console.log({ err });
        });

      getUnitNumberTotal('facematch', startDate, endDate).then((response) => {
        setFaceList([]);
        setFaceList(response);
      })
        .catch((err) => {
          setLocalLoading(false);
          console.log({ err });
        })

      // deleted
      getNumberTotal('delete', startDate, endDate).then((response) => {
        setDeleted([]);
        setDeleted(response);
      })
        .catch((err) => {
          setLocalLoading(false);
          console.log({ err });
        });

      getUnitNumberTotal('delete', startDate, endDate).then((response) => {
        setDeleteList([]);
        setDeleteList(response);
      })
        .catch((err) => {
          setLocalLoading(false);
          console.log({ err });
        })

      // update picture
      getNumberTotal('update', startDate, endDate).then((response) => {
        setUpdatePicture([]);
        setUpdatePicture(response);
      })
        .catch((err) => {
          setLocalLoading(false);
          console.log({ err });
        });

      getUnitNumberTotal('update', startDate, endDate).then((response) => {
        setUpdatePictureList([]);
        setUpdatePictureList(response);
      })
        .catch((err) => {
          setLocalLoading(false);
          console.log({ err });
        })
    }
    setLocalLoading(false);
  }


  const data = [
    {
      title: "CADASTRO",
      icon: imageBoneco,
      bgColor: "#FFF",
      value: Object.values(cad).length === 0 ? 0 : cad.total,
      textColor: "#6DBCDB",
      height: 200,
      width: 140,
      cardMarginTop: 10,
      textMarginTop: 0,
    },
    {
      title: "RECONHECIMENTO FACIAL",
      icon: imageFace,
      bgColor: "#FFE2E2",
      value: Object.values(face).length === 0 ? 0 : face.total,
      textColor: "#FF0000",
      height: 200,
      width: 140,
      cardMarginTop: 10,
      textMarginTop: 0,
    },
    {
      title: "EXCLUSÃO",
      icon: imageExclusao,
      bgColor: "#1E85F1A3",
      value: Object.values(deleted).length === 0 ? 0 : deleted.total,
      textColor: "#FFF",
      height: 85,
      width: 55,
      cardMarginTop: 55,
      textMarginTop: 40,
      disableIdClick: true,
    },
    {
      title: "TROCA DE IMAGEM",
      icon: imageFoto,
      bgColor: "#7A86FAD6",
      value: Object.values(updatePicture).length === 0 ? 0 : updatePicture.total,
      textColor: "#FFF",
      height: 85,
      width: 60,
      cardMarginTop: 50,
      textMarginTop: 40,
      disableIdClick: true,
    }
  ]

  const onChange = (ranges) => {
    setDate(ranges);
  }

  const handleExtractXls = () => {
    const registerObj = {};
    cadList.forEach((cad) => {
      registerObj[cad.unit] = cad.total;
    })
    const matchObj = {};
    faceList.forEach((match) => {
      matchObj[match.unit] = match.total;
    })
    const deleteObj = {};
    deleteList.forEach((del) => {
      deleteObj[del.unit] = del.total;
    })
    const updateObj = {};
    updatePictureList.forEach((update) => {
      updateObj[update.unit] = update.total;
    })
    const xlsData = [
      {
        sheet: `Geral ${moment(date.startDate).format('DD-MM-YYYY')}-${moment(date.endDate).format('DD-MM-YYYY')}`,
        columns: [
          { label: 'Cadastro', value: 'register' },
          { label: 'Reconhecimento Facial', value: 'match' },
          { label: 'Exclusão', value: 'delete' },
          { label: 'Troca de imagem', value: 'image_update' }
        ],
        content: [{
          register: Object.values(cad).length ? cad.total : 0,
          match: Object.values(face).length ? face.total : 0,
          delete: Object.values(deleted).length ? deleted.total : 0,
          image_update: Object.values(updatePicture).length ? updatePicture.total : 0
        }]
      }]
    if (cadList.length) {
      xlsData.push({
        sheet: `Cadastro`,
        columns: cadList.map((cad) => ({
          label: cad.unit, value: cad.unit
        })),
        content: [registerObj]
      })
    }
    if (faceList.length) {
      xlsData.push({
        sheet: `Reconhecimento facial`,
        columns: faceList.map((match) => ({
          label: match.unit, value: match.unit
        })),
        content: [matchObj]
      })
    }
    if (deleteList.length) {
      xlsData.push({
        sheet: `Exclusão`,
        columns: deleteList.map((del) => ({
          label: del.unit, value: del.unit
        })),
        content: [deleteObj]
      })
    }
    if (updatePictureList.length) {
      xlsData.push({
        sheet: `Troca de imagem`,
        columns: updatePictureList.map((update) => ({
          label: update.unit, value: update.unit
        })),
        content: [updateObj]
      })
    }

    const settings = {
      fileName: `Dashboard@${moment().format('YYYY-MM-DD HH:mm:ss')}`
    }

    jsonAsXlsx(xlsData, settings);
  }

  if (globalLoading) {
    return (
      <div className="HomeDashLoad">
        <Dots color="#000" />
      </div>
    )
  }

  return (
    <div className="Dash">

      {!isShowCalendar ? <div className="DateRange">
        <CCard className="card-date" style={isShowCalendar === false ? { height: 46 } : {}}>
          <CCardBody style={{ marginTop: -7 }}>
            <CRow>
              <div className="div-card" onClick={() => isShowCalendar ? setIsShowCalendar(false) : setIsShowCalendar(true)}>
                <CImage src={calendar} width={25} height={25} />
                <CCardText className="text-date cursor-pointer">
                  {Object.values(date).length === 0 ?
                    'Escolha uma data' :
                    `${moment(date.startDate).format('MMM DD[,] YYYY')} / ${moment(date.endDate).format('MMM DD[,] YYYY')}`}
                </CCardText>
              </div>
            </CRow>
          </CCardBody>
        </CCard>
        <CButton
          className='button-xls'
          onClick={handleExtractXls}
        >Extrair XLS</CButton>
      </div> : ''}

      {!localLoading && <div style={isShowCalendar ? { position: 'relative', top: 76 } : {}}>
        <CRow className="row-dash">
          {data.map((data, index) => (
            <CCol>
              <Card
                title={data.title}
                icon={data.icon}
                bgColor={data.bgColor}
                value={data.value}
                textColor={data.textColor}
                height={data.height}
                width={data.width}
                cardMarginTop={data.cardMarginTop}
                textMarginTop={data.textMarginTop}
                onClick={() => setViewData(index)}
                highlighted={viewData === index}
              />
            </CCol>
          ))}
        </CRow>

        <CCardText className="title-und">Acesso por unidade</CCardText>

        {viewData === 0 ?
          cadList.map((data) => (
            <CCol>
              <CardList
                nameUnd={data.unit}
                colorProgress={"success"}
                porcent={(data.total / cad.total) * 100}
                value={`${data.total} / ${cad.total}`}
              />
            </CCol>
          )) : ''}
        {viewData === 1 ?
          faceList.map((data) => (
            <CCol>
              <CardList
                nameUnd={data.unit}
                colorProgress={"warning"}
                porcent={(data.total / face.total) * 100}
                value={`${data.total} / ${face.total}`}
              />
            </CCol>
          )) : ''}
        {viewData === 2 ?
          deleteList.map((data) => (
            <CCol>
              <CardList
                nameUnd={data.unit}
                colorProgress={"info"}
                porcent={(data.total / deleted.total) * 100}
                value={`${data.total} / ${deleted.total}`}
              />
            </CCol>
          )) : ''}

        {viewData === 3 ?
          updatePictureList.map((data) => (
            <CCol>
              <CardList
                nameUnd={data.unit}
                colorProgress={""}
                porcent={(data.total / updatePicture.total) * 100}
                value={`${data.total} / ${updatePicture.total}`}
              />
            </CCol>
          )) : ''}
        <br></br>
      </div>}

      {isShowCalendar ? <div className="DateRange" style={{ position: 'absolute' }}>
        <CCard className="card-date" style={isShowCalendar === false ? { height: 46 } : {}}>
          <CCardBody style={{ marginTop: -7 }}>
            <CRow>
              <div className="div-card" onClick={() => isShowCalendar ? setIsShowCalendar(false) : setIsShowCalendar(true)}>
                <CImage src={calendar} width={25} height={25} />
                <CCardText className="text-date cursor-pointer">
                  {Object.values(date).length === 0 ?
                    'Escolha uma data' :
                    `${moment(date.startDate).format('MMM DD[,] YYYY')} / ${moment(date.endDate).format('MMM DD[,] YYYY')}`}
                </CCardText>
              </div>
            </CRow>
            {isShowCalendar === true ?
              <div>
                <CCol>
                  <DateRangeFilter onChange={onChange} />
                </CCol>
                <div className="div-button-calendar">
                  {/* <CButton className='button-limpar-date' onClick={()=>setDate([])}>Limpar</CButton> */}
                  <CButton className='button-confirmar-date' onClick={handleClick} >Confirmar</CButton>
                </div>
              </div> :
              ''}
          </CCardBody>
        </CCard>
        <CButton className='button-xls'>Extrair XLS</CButton>
      </div> : ''}

      {localLoading &&
        <div className="Loading">
          <Dots color='#000' />
        </div>
      }
    </div>
  )
}

export default Dashboard;