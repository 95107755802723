import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    isAuthorized: true,
};

export const setState = createAction('SET_STATE');
export const resetState = createAction('RESET_STATE'); 

export default createReducer(INITIAL_STATE, {
    [setState.type]: (state, action) => ({ ...state, ...action.payload }),
    [resetState.type]: () => INITIAL_STATE,
});