import React, { useEffect, useState } from 'react';
import './styles.css';
import Pagination from '../../components/Pagination';
import SearchBar from '../../components/SearchBar';
import AdminTable from '../../components/AdminTable';
// import { useAuth } from 'oidc-react';
import { getPerson } from '../../services/httpRequest';
import { Dots } from 'react-activity';
import 'react-activity/dist/Dots.css';

const Home = () => {

    // const auth = useAuth();
    const [person, setPerson] = useState([]);
    const [id, setId] = useState('');
    const [unit, setUnit] = useState('');
    const [totem, setTotem] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('00:00');
    const [orderBy, setOrderBy] = useState(undefined);
    const [orderType, setOrderType] = useState('ASC');
    const [globalLoading, setGlobalLoading] = useState(true);
    const [localLoading, setLocalLoading] = useState(false);

    // pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
            setGlobalLoading(true)
            getPerson(1, 10).then((response) => {
                setPerson(response.peopleList);
                setTotalPages(response.total_page);
                setCurrentPage(1);
                setGlobalLoading(false)
            })
                .catch((err) => {
                    console.log({ err });
                    setGlobalLoading(false)
                });
    }, [
        setPerson,
        // auth.userData
    ]);

    const changePage = (pageNumber) => {
        if (pageNumber !== 0 && pageNumber <= totalPages) {
            setLocalLoading(true)
            getPerson(pageNumber, 10, id, totem, unit, date, orderBy, orderType).then((response) => {
                setTotalPages(response.total_page);
                setCurrentPage(pageNumber);
                setPerson(response.peopleList);
                setLocalLoading(false)
            })
        }
    }

    const handleClick = () => {
        if (setPerson) {
            setLocalLoading(true)
            getPerson(1, 10, id, totem, unit, date, orderBy, orderType).then((response) => {
                setPerson([]);
                setPerson(response.peopleList)
                setCurrentPage(1);
                setTotalPages(response.total_page);
                setLocalLoading(false)
            })
        }
    }

    const handleOrderByChange = (_orderBy, _orderType) => {
        // if (auth.userData) {
            setLocalLoading(true)
            getPerson(currentPage, 10, id, totem, unit, date, _orderBy, _orderType).then((response) => {
                setPerson([]);
                setPerson(response.peopleList);
                setLocalLoading(false)
            })
        // }
    }

    if (globalLoading) {
        return (
            <div className="HomePageLoad">
                <Dots color="#000" />
            </div>
        )
    }

    return (
        <div className='Home'>
            <SearchBar
                setPerson={setPerson}
                id={id}
                totem={totem}
                unit={unit}
                date={date}
                time={time}
                setId={setId}
                setTotem={setTotem}
                setUnit={setUnit}
                setDate={setDate}
                setTime={setTime}
                handleClick={handleClick}
            />
            <div className='TableContainer'>
                <div className='TableCard'>
                    <AdminTable
                        tableData={person}
                        orderBy={orderBy}
                        orderType={orderType}
                        setOrderBy={setOrderBy}
                        setOrderType={setOrderType}
                        handleOrderByChange={handleOrderByChange}
                        localLoading={localLoading}
                    />
                </div>
                {!localLoading && <Pagination
                    changePage={changePage}
                    currentPage={currentPage}
                    totalPages={totalPages}
                />}
            </div>
        </div>
    );
}

export default Home;
