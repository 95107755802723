import React from "react";
import { CCardBody, CCard, CRow, CCol, CCardText, CImage } from '@coreui/react'
import "bootstrap/dist/css/bootstrap.min.css"
import './style.css'

const Card = ({ title, icon, bgColor, value, textColor, height, width, cardMarginTop, textMarginTop, onClick, highlighted }) => {

  const opacity = highlighted ? { opacity: '1' } : { opacity: '0.5' };

  return (
    <CRow>
      <CCol>
        <CCard style={{ backgroundColor: [bgColor], ...opacity }}
          className={'card-one cursor-pointer'}
          onClick={onClick}>
          <CCardBody style={{ marginTop: cardMarginTop }}>
            <CImage align="center" rounded src={icon} width={height} height={width} />
            <CCardText className="cardTitle" style={{ color: textColor, marginTop: textMarginTop }}>{title}</CCardText>
            <CCardText className="cardSubTitle" style={{ color: textColor }}>{value}</CCardText>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
}

export default Card