import React from 'react';
import './styles.css';

import { Link, Outlet, useLocation } from "react-router-dom";
import homeIcon from '../../assets/home.svg';
import dashboardIcon from '../../assets/dashboard.svg'

const Menu = () => {

    const location = useLocation();

    return (
        <div className='Container'>
            <div className='Menu'>
                <nav>
                    <div className='LinkContainer'>
                        <img src={homeIcon} alt='Home' />
                        <Link
                            to='/'
                            className={`Menu-Link ${['/', '/history'].includes(location.pathname) ? 'Menu-LinkSelected' : ''}`}
                        >Home</Link>
                    </div>

                    <div className='LinkContainer'>
                        <img src={dashboardIcon} alt='Dashboard' />
                        <Link
                            to='/dashboard'
                            className={`Menu-Link ${['/dashboard'].includes(location.pathname) ? 'Menu-LinkSelected' : ''}`}
                        >Dashboard</Link>
                    </div>
                </nav>
            </div>
            <div className='Content'>
                <Outlet />
            </div>
        </div>
    )
}

export default Menu;
