import React from "react";
import './styles.css';

import { useNavigate } from 'react-router-dom';

import buttonIcon from '../../assets/Icon feather-search (1).svg';
import backIcon from '../../assets/seta.svg';

const SearchBar = ({ returnBtn, id, totem, unit, date, setId, setTotem, setUnit, setDate, handleClick }) => {

    const navigate = useNavigate();

    const callSearch = (key) => {
        if (key === 'Enter') handleClick();
    }

    return (
        <div className='SearchBar'>
            <div>
                <input
                    className='IdInput'
                    type="text"
                    placeholder='Pesquise por ID'
                    value={id}
                    onChange={event => setId(event.target.value)}
                    onKeyUp={(event) => callSearch(event.key)}
                />

                <input
                    className='TotemInput'
                    type="text"
                    placeholder='Pesquise por Totem'
                    value={totem}
                    onChange={event => setTotem(event.target.value)}
                    onKeyUp={(event) => callSearch(event.key)}
                />

                <input
                    className='UnitInput'
                    type="text"
                    placeholder='Pesquise por Unidade'
                    value={unit}
                    onChange={event => setUnit(event.target.value)}
                    onKeyUp={(event) => callSearch(event.key)}
                />

                <input
                    className='Date'
                    type="date"
                    value={date}
                    onChange={(event) => setDate(event.target.value)}
                    onKeyUp={(event) => callSearch(event.key)}
                />

                <button className='Button' onClick={handleClick}>
                    <img src={buttonIcon} alt='Search' />
                </button>
            </div>

            {returnBtn && <div>
                <button className='ReturnBtn' onClick={() => navigate(-1)}>
                    <img src={backIcon} alt='Back' />
                </button>
            </div>}
        </div>
    );
}

export default SearchBar;
