import React, { useState, useEffect, useCallback } from 'react';
import './styles.css';
import moment from 'moment';
import 'moment/locale/pt-br';

import { useLocation, useNavigate } from 'react-router-dom';
import AdminTableSelectedData from '../../components/AdminTableSelectedData';
import HistoryTable from '../../components/HistoryTable';
// import { useAuth } from 'oidc-react';
import { getPersonLogById } from '../../services/httpRequest';
import backIcon from '../../assets/seta.svg';

const AccessHistory = () => {

    moment.locale('pt-br');
    const navigate = useNavigate();

    // const auth = useAuth();
    const { rowData } = useLocation().state;

    const [log, setLog] = useState([]);
    const [orderBy, setOrderBy] = useState('datetime');
    const [orderType, setOrderType] = useState('ASC');
    const [loading, setLoading] = useState(true);

    const treatResponse = (response) => {
        const treatedResponse = response.map((log) => {
            return {
                clerk: log.Event.description === 'create' ? log.Person.id_table : 'Mateus Silva',
                action: log.Event.description,
                datetime: moment(log.datetime).format(),
                date: moment(log.datetime).format('DD MMMM YYYY'),
                time: moment(log.datetime).format('HH:mm [hrs]')
            }
        })
        setLog(treatedResponse);
        setLoading(false);
    }

    const loadLogs = useCallback(() => {
        getPersonLogById(rowData.id_person)
            .then((response) => {
                treatResponse(response);
            })
            .catch((err) => {
                console.log({ err });
                setLoading(false);
            });
    }, [
        // auth.userData,
        rowData.id_person
    ]);

    useEffect(() => {
        loadLogs()
    }, [loadLogs]);

    const clerkOrdering = (type) => {
        const sorted = log.sort((a, b) => {
            return (a.clerk).localeCompare(b.clerk) * type;
        })
        setLog(sorted);
        setLoading(false);
    }
    const actionOrdering = (type) => {
        const sorted = log.sort((a, b) => {
            if (a.action > b.action) return 1 * type;
            if (a.action < b.action) return -1 * type;
            return 0;
        })
        setLog(sorted);
        setLoading(false);
    }
    const datetimeOrdering = (type) => {
        const sorted = log.sort((a, b) => {
            if (a.datetime > b.datetime) return 1 * type;
            if (a.datetime < b.datetime) return -1 * type;
            return 0;
        })
        setLog(sorted);
        setLoading(false);
    }
    const dateOrdering = (type) => {
        const sorted = log.sort((a, b) => {
            if (a.date > b.date) return 1 * type;
            if (a.date < b.date) return -1 * type;
            return 0;
        })
        setLog(sorted);
        setLoading(false);
    }
    const timeOrdering = (type) => {
        const sorted = log.sort((a, b) => {
            if (a.time > b.time) return 1 * type;
            if (a.time < b.time) return -1 * type;
            return 0;
        })
        setLog(sorted);
        setLoading(false);
    }

    const orderingObj = {
        action: (type) => actionOrdering(type),
        clerk: (type) => clerkOrdering(type),
        date: (type) => dateOrdering(type),
        datetime: (type) => datetimeOrdering(type),
        time: (type) => timeOrdering(type),
    }

    const handleOrderByChange = (_orderBy, _orderType) => {
        setLoading(true);
        const orderingType = _orderType === 'ASC' ? 1 : -1;
        orderingObj[_orderBy](orderingType);
    }

    return (
        <div className='AccessHistory'>
            <div className="ReturnButton">
                <button className='ReturnBtn' onClick={() => navigate(-1)}>
                    <img src={backIcon} alt='Back' />
                </button>
            </div>
            <div className='TableContainer'>
                <div className='TableCard'>
                    <AdminTableSelectedData
                        tableData={[rowData]}
                    />
                    <HistoryTable
                        tableData={log}
                        id_table={rowData.id_table}
                        orderBy={orderBy}
                        orderType={orderType}
                        setOrderBy={setOrderBy}
                        setOrderType={setOrderType}
                        handleOrderByChange={handleOrderByChange}
                        loading={loading}
                    />
                </div>

            </div>
        </div>
    );
}

export default AccessHistory;
