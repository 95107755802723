import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import rootUser from './Ducks/User';

// Combining reducers
const reducers = combineReducers({
    user: rootUser,
});

// Setting persist configurations
const persistConfig = {
    key: 'portal',
    storage,
    version: 1,
    stateReconciler: autoMergeLevel2,
};

// Creating persist reducer
const persistedReducer = persistReducer(persistConfig, reducers);

// Configuring store with persisted reducers
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };