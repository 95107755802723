import React from "react";
import nextIcon from '../../assets/next.svg';
import previousIcon from '../../assets/previous.svg';
import './styles.css';

const Pagination = ({ changePage, currentPage, totalPages}) => {

    return (
        <div className='bottom-button-container'>
            <div className='pagination-button-wrapper'>
                <div className="pagination-text1">{currentPage < 10 ? `0${currentPage}` : currentPage}</div>
                <div className="pagination-text2">de {totalPages}</div>
                <button
                    className='pagination-button-navigation'
                    style={currentPage === 1 ? {opacity: 0.5} : {opacity: 1}}
                    onClick={() => changePage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    <img alt='prev' style={{width: 7}} src={previousIcon} />
                </button>
                <button
                    className='pagination-button-navigation'
                    style={currentPage === totalPages ? {opacity: 0.5} : {opacity: 1}}
                    onClick={() => changePage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    <img alt='next' style={{width: 7}} src={nextIcon} />
                </button>
            </div>
        </div>
    );
}

export default Pagination;

