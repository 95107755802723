import React from "react";
import './styles.css';

import moment from "moment";

const AdminTableSelectedData = ({ tableData = [] }) => {
    return (
        <div
            className='AdminTableSelectedData'
        >
            <table>
                <thead>
                    <tr>
                        <th>Foto</th>
                        <th>ID</th>
                        <th>Totem</th>
                        <th>Unidade</th>
                        <th>Data de cadastro</th>
                        <th>Hora de cadastro</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((data, index) => (
                        <tr key={index}>
                            <td>
                                <div className='ImagePlaceHolder'>JPG</div>
                            </td>
                            <td>
                                {data.id_table}
                            </td>
                            <td className='text-medium'>
                                {data.totem_name}
                            </td>
                            <td>
                                {data.unit}
                            </td>
                            <td>
                                {moment(data.datetime).format('DD/MM/yyyy')}
                            </td>
                            <td>
                                {moment(data.datetime).format('HH:mm')}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default AdminTableSelectedData;
