import React from 'react';
import './App.css';
// import { AuthProvider } from 'oidc-react';
import Router from './router';

// const oidcConfig = {
//   onSignIn: (_) => { },
//   clientId: 'portal',
//   redirectUri: 'http://localhost:3000/',
//   authority: process.env.REACT_APP_KEY_CLOAK,
//   clientSecret: 'Fo35Lmsjj6YDxKn4xIJ1d8U4LPQg4C97'
// };

function App() {

  return (
    // <AuthProvider {...oidcConfig}>
      <Router />
    // </AuthProvider>
  );
}

export default App;
