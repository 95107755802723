import moment from 'moment';
import 'moment/locale/pt-br';
import React from 'react';
import './styles.css';
import xlsx from '../../utils/xlsx';
import ArrowUp from '../../assets/arrow-up.svg';
import ArrowDown from '../../assets/arrow-down.svg';
import { Dots } from 'react-activity';
import 'react-activity/dist/Dots.css';

const HistoryTable = ({ tableData, id_table, orderBy, orderType, setOrderBy, setOrderType, handleOrderByChange, loading }) => {

    moment.locale('pt-br');

    const handleExtractXls = () => {
        const columns = [
            { label: 'Atendente', value: 'clerk' },
            { label: 'Ação', value: 'action' },
            { label: 'Data da ação', value: 'date' },
            { label: 'Hora da ação', value: 'time' }
        ]

        xlsx.generate(columns, tableData, `ID ${id_table}`, `ID${id_table}@${moment().format('YYYY-MM-DD HH:mm:ss')}`);
    }

    const handleTableHeaderClick = (header) => {
        // when order by changes, set it and send type as ascending.
        if (orderBy !== header) {
            setOrderBy(header);
            setOrderType('ASC');
            handleOrderByChange(header, 'ASC');
            return;
        }
        // when order by is the same and type is asc, change type to descending.
        if (orderType === 'ASC') {
            setOrderType('DESC');
            handleOrderByChange(header, 'DESC');
            return;
        }
        // Last possibility is when order by is the same as previous one and type is decending.
        // Now, remove filter by setting order by to undefined
        setOrderBy('datetime');
        setOrderType('ASC');
        handleOrderByChange('datetime', 'ASC');
    }

    return (
        <div className='HistoryTable'>
            <div className='HeaderContainer'>
                <div className='Title'>
                    Histórico de acesso
                </div>
                <button
                    onClick={handleExtractXls}
                    disabled={loading}
                >
                    Extrair XLS
                </button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th
                            onClick={() => handleTableHeaderClick('clerk')}
                            className={`hoverBlue cursor-pointer ${orderBy === 'clerk' ? 'orderActive' : ''}`}
                        >
                            Atendente
                            {orderBy === 'clerk' && orderType === 'ASC' &&
                                <img
                                    src={ArrowUp}
                                    alt='up'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                />
                            }
                            {orderBy === 'clerk' && orderType === 'DESC' &&
                                <img
                                    src={ArrowDown}
                                    alt='down'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                />
                            }
                        </th>
                        <th
                            onClick={() => handleTableHeaderClick('action')}
                            className={`hoverBlue cursor-pointer ${orderBy === 'action' ? 'orderActive' : ''}`}
                        >
                            Ação
                            {orderBy === 'action' && orderType === 'ASC' &&
                                <img
                                    src={ArrowUp}
                                    alt='up'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                />
                            }
                            {orderBy === 'action' && orderType === 'DESC' &&
                                <img
                                    src={ArrowDown}
                                    alt='down'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                />
                            }
                        </th>
                        <th
                            onClick={() => handleTableHeaderClick('date')}
                            className={`hoverBlue cursor-pointer ${orderBy === 'date' ? 'orderActive' : ''}`}
                        >
                            Data da ação
                            {orderBy === 'date' && orderType === 'ASC' &&
                                <img
                                    src={ArrowUp}
                                    alt='up'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                />
                            }
                            {orderBy === 'date' && orderType === 'DESC' &&
                                <img
                                    src={ArrowDown}
                                    alt='down'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                />
                            }
                        </th>
                        <th
                            onClick={() => handleTableHeaderClick('time')}
                            className={`hoverBlue cursor-pointer ${orderBy === 'time' ? 'orderActive' : ''}`}
                        >
                            Hora da ação
                            {orderBy === 'time' && orderType === 'ASC' &&
                                <img
                                    src={ArrowUp}
                                    alt='up'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                />
                            }
                            {orderBy === 'time' && orderType === 'DESC' &&
                                <img
                                    src={ArrowDown}
                                    alt='down'
                                    height={7}
                                    width={12}
                                    className="arrowImage"
                                />
                            }
                        </th>
                    </tr>
                </thead>
                {!loading &&
                    <tbody>
                        {tableData.map((data, index) => (
                            <tr key={index}>
                                <td>
                                    {data.clerk}
                                </td>
                                <td>
                                    {data.action}
                                </td>
                                <td>
                                    {data.date}
                                </td>
                                <td>
                                    {data.time}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                }
            </table>
            {loading &&
                <div className='loadingContainer'>
                    <Dots color="#000" />
                </div>
            }
        </div>
    );
}

export default HistoryTable;
